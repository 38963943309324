import { useState, useEffect, useRef } from 'react';
import { styled } from 'Theme/stitches.config';
import { Variant } from 'Enums/Variant.enum';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import { GetCart, SetQuantity, UpdateQuantity } from 'Commerce/Cart/Cart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import Error from 'DesignComponents/Atoms/OLD/Error/Error';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import Price from 'Commerce/Atom/Price/Price';
import Button from 'Shared/Components/Buttons/Button';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { mapModalView } from 'DesignComponents/Organisms/Modal/ModalContentViews';
import MiniCartContent from 'Commerce/Cart/MiniCart/MiniCartContent';
import { mapModalFooterView } from 'DesignComponents/Organisms/Modal/ModalFooterViews';
import MiniCartModalFooter from 'Commerce/Cart/MiniCart/MiniCartModalFooter';
import { ModalFooterLayoutOption } from 'Enums/ModalFooterLayout.enum';
import SubscriptionOfferProductPageModel from 'Models/Pages/ProductPage/SubscriptionOfferProductPageModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import PromptPopup from 'DesignComponents/Organisms/PromptPopup/PromptPopup';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import SwitchShippingCountryCTA from './SwitchShippingCountryCTA';

function ProductCTA() {
  const [quantity, setQuantity] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBuyDisabled, setIsBuyDisabled] = useState<boolean>(false);
  const [isAddToCartError, setAddToCartError] = useState<boolean>(false);
  const [replacePopup, setReplacePopup] = useState({
    isVisible: false,
    hasConfirmToReplace: true,
  });
  const { modalState, toggleModal } = useUiState();
  const errorCartRef = useRef<HTMLSpanElement>(null);
  useOutsideClick(errorCartRef, () => setAddToCartError(false));

  const { languageRoute } = useAppSettingsData();

  const productData = useCurrentPage<SubscriptionOfferProductPageModel>();
  const {
    displayPromptPopup,
    promptPopupModelReplaceSubscriptionOfferInCart: {
      actionButtonText = '',
      bodyText: popupBodyText = '',
      cancelButtonText = '',
      heading: popupHeading = '',
    } = {},
  } = productData || {};

  const {
    productLabels: { addToCart },
    cartLabels: { cartHeader },
  } = useTranslationData();

  const { cart } = GetCart(languageRoute);

  const { state, variantDispatch } = useVariantData();

  const onActionButtonClick = () => {
    if (canUseDOM()) {
      const addItemToCartButton = document.getElementById(
        'addItemToCartButton'
      );

      if (addItemToCartButton) {
        setReplacePopup((prev) => ({
          ...prev,
          hasConfirmToReplace: false,
          isVisible: false,
        }));

        setTimeout(() => {
          addItemToCartButton.click();
        }, 700);
      }
    }
  };

  useEffect(() => {
    if (state.selectedVariant != null) {
      setIsBuyDisabled(!state.selectedVariant.isBuyable);
    }
  }, [state.selectedVariant, cart]);

  useEffect(() => {
    setQuantity(1);
  }, [state.selectedVariant]);

  const onOpenMiniCart = () => {
    toggleModal(
      !modalState.display,
      'MiniCart',
      cartHeader,
      {
        component: mapModalView<typeof MiniCartContent>({
          type: 'MiniCart',
          props: {},
        }),
      },
      mapModalFooterView<typeof MiniCartModalFooter>({
        type: 'MiniCartModalFooter',
        props: {
          displayModal: !modalState.display,
          modalFooterContent: {
            footerLayout: ModalFooterLayoutOption.STACKED,
          },
        },
      }),
      undefined
    );
  };

  function handleAddToCart(variation: VariationModel, quantity: number) {
    const code = variation.code;
    setIsLoading(true);

    if (cart && cart.lineItems) {
      const isProductInCart = !!cart.lineItems.find(
        (item) => item.code === code
      );
      if (isProductInCart) {
        UpdateQuantity(variation, quantity, languageRoute)
          .then(() => {
            setIsLoading(false);

            setTimeout(() => {
              onOpenMiniCart();
            }, 1000);
          })
          .catch(() => setIsLoading(false));
      } else {
        SetQuantity(variation, quantity, variation.quantity, languageRoute)
          .then(() => {
            setIsLoading(false);
            setTimeout(() => {
              onOpenMiniCart();
            }, 1000);
          })
          .catch(() => setIsLoading(false));
      }
    }

    variantDispatch({
      type: Variant.SET_ADD_CART_ERROR,
      errorSize: false,
    });
  }

  const variant = state.selectedVariant;
  if (!variant) return <></>;

  return (
    <Root>
      <Row
        css={{
          justifyContent: 'space-between',
          marginTop: 0,
        }}
      >
        <Price
          wasPrice={variant.wasPrice}
          nowPrice={variant.nowPrice}
          css={{ mt: 8, mb: 4 }}
        />
      </Row>
      <RowButton>
        <ProductCTAContainer>
          <Button<typeof PrimaryButton>
            props={{
              text: variant.isBuyable ? addToCart : variant.notBuyableReason,
              disabled: isBuyDisabled || isLoading,
              color: 'Regular',
              size: 'm',
              hug: 'width',
            }}
            id="addItemToCartButton"
            element="PrimaryButton"
            onClick={() =>
              displayPromptPopup && replacePopup.hasConfirmToReplace
                ? setReplacePopup((prev) => ({
                    ...prev,
                    isVisible: true,
                  }))
                : variant &&
                  !isBuyDisabled &&
                  !isLoading &&
                  handleAddToCart(variant, quantity)
            }
            isLoading={isLoading}
          />
        </ProductCTAContainer>

        <SwitchShippingCountryCTA />
      </RowButton>

      {isAddToCartError && (
        <Error errorLabel="Add to cart error message" ref={errorCartRef} />
      )}
      <PromptPopup
        popupHeading={popupHeading}
        popupBodyText={popupBodyText}
        IsOpen={replacePopup.isVisible}
        CancelButtonText={cancelButtonText as string}
        ActionButtonText={actionButtonText as string}
        onCancelButtonClick={() =>
          setReplacePopup((prev) => ({
            ...prev,
            isVisible: false,
          }))
        }
        onActionButtonClick={onActionButtonClick}
      ></PromptPopup>
    </Root>
  );
}

const rowStyles = {
  display: 'flex',
  w: '100%',
  alignItems: 'center',
  gap: '$s50',
};

const Row = styled('div', {
  mt: '$s100',
  ...rowStyles,
});

const RowButton = styled('div', {
  ...rowStyles,
  display: 'block',
});

const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mb: '$s200',
});

const ProductCTAContainer = styled('div', {
  display: 'flex',
  w: '100%',
});

export default ProductCTA;
